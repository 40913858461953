import React, { useCallback, useEffect, useState } from "react";
import cn from "classnames";
import Svg from "react-inlinesvg";

import hamburger from "../../assets/hamburger.svg";
import wave from "../../assets/waves/bottom-white.svg";

import CustomLink from "../Link";

import logo from "../../assets/logos/logo.png";
import logoWhite from "../../assets/logos/logo-white.png";

import styles from "./styles.module.scss";

interface Props {
  openMenu: () => void;
}

function TopBar({ openMenu }: Props) {
  const [whiteBackground, setWhiteBackground] = useState(
    document.documentElement.scrollTop > 100
  );

  const handleScroll = useCallback(() => {
    setWhiteBackground(document.documentElement.scrollTop > 100);
  }, []);

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => document.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  return (
    <header
      id="topbar"
      className={cn("row middle show-sm", styles.container, {
        [styles.white]: whiteBackground
      })}
    >
      <button
        type="button"
        onClick={openMenu}
        className={`row start ${styles.hamburger}`}
      >
        <Svg
          src={hamburger}
          className={cn(styles.hamburgerSvg, {
            [styles.white]: !whiteBackground
          })}
        />
      </button>
      <CustomLink to="/" className="row center full-width item-1">
        <img
          src={whiteBackground ? logo : logoWhite}
          alt="letramp"
          className={styles.logo}
        />
      </CustomLink>
      <div className="item-1" />
      <img src={wave} alt="" className={styles.wave} />
    </header>
  );
}

export default TopBar;
