export const OPTIONS = [
  {
    label: "quienes somos",
    route: "/quienes-somos"
  },
  {
    label: "horarios",
    route: "/horarios"
  },
  {
    label: "trampolin",
    route: "/trampolin"
  },
  {
    label: "acrosport",
    route: "/acrosport"
  }
];

export default OPTIONS;
