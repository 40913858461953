import React, {
  useCallback,
  useState,
  useRef,
  useEffect,
  LegacyRef
} from "react";
import cn from "classnames";

import { useOutsideClick } from "../../hooks/useOutsideClick";

import CustomLink from "../Link";

import { OPTIONS } from "./constants";

import styles from "./styles.module.scss";

interface Props {
  show: boolean;
  onClose: () => void;
}

function Menu({ show, onClose }: Props) {
  const menuRef = useRef<HTMLDivElement>();
  const [fadeOutStyle, setFadeOutStyle] = useState(false);
  const clickOutside = useOutsideClick({ ref: menuRef, isOpen: show });

  const handleClose = useCallback(() => {
    setFadeOutStyle(true);
    setTimeout(() => {
      onClose();
      setFadeOutStyle(false);
    }, 300);
  }, [onClose]);

  useEffect(() => {
    if (clickOutside) {
      handleClose();
    }
  }, [clickOutside, handleClose]);

  return (
    <div
      id="topbar"
      ref={menuRef as LegacyRef<HTMLDivElement>}
      className={cn(
        "column show-sm",
        styles.container,
        fadeOutStyle ? styles.fadeOut : styles.fadeIn,
        {
          [styles.hide]: !show
        }
      )}
    >
      <button
        type="button"
        onClick={handleClose}
        className="white self-end m-bottom-20 m-right-20"
      >
        x
      </button>
      {OPTIONS.map((option) => (
        <CustomLink
          key={option.label}
          to={option.route}
          className={`row middle full-width ${styles.option}`}
          callback={handleClose}
        >
          <span className="header-h6 white">{option.label}</span>
        </CustomLink>
      ))}
    </div>
  );
}

export default Menu;
