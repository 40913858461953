import { lazy } from "react";

const home = lazy(() => import("./screens/Home"));
const trampolin = lazy(() => import("./screens/Trampolin"));
const acrosport = lazy(() => import("./screens/Acrosport"));
const horarios = lazy(() => import("./screens/Horarios"));
const quienesSomos = lazy(() => import("./screens/QuienesSomos"));
const eventos = lazy(() => import("./screens/Eventos"));

export const ROUTES = [
  {
    id: "quienes-somos",
    exact: false,
    path: "/quienes-somos",
    component: quienesSomos
  },
  {
    id: "trampolin",
    exact: false,
    path: "/trampolin",
    component: trampolin
  },
  {
    id: "acrosport",
    exact: false,
    path: "/acrosport",
    component: acrosport
  },
  {
    id: "horarios",
    exact: false,
    path: "/horarios",
    component: horarios
  },
  {
    id: "eventos",
    exact: false,
    path: "/eventos",
    component: eventos
  },
  {
    id: "homepage",
    exact: false,
    path: "/",
    component: home
  }
];

export default ROUTES;
