import camelcase from "lodash.camelcase";

export const snakecase = (value: string): string =>
  value
    .replace(/[\w]([A-Z])/g, (word) => `${word[0]}_${word[1]}`)
    .toLowerCase();

export const serializer = (
  response: Array<Record<string, any>> | Record<string, any>
): Array<Record<string, any>> | Record<string, any> => {
  if (!response) {
    return response;
  }

  if (Array.isArray(response)) {
    return response.map((elem) => serializer(elem));
  }
  if (typeof response === "object") {
    const res = {} as Record<string, any>;
    Object.entries(response).forEach(([key, value]) => {
      const newKey = camelcase(key);
      res[newKey] = value ? serializer(value) : value;
    });
    return res;
  }

  return response;
};

export const deserializer = (
  params: Array<Record<string, any>> | Record<string, any> | any
): Array<Record<string, any>> | Record<string, any> | any => {
  if (params === "") {
    return null;
  }

  if (!params) {
    return params;
  }

  if (Array.isArray(params)) {
    return params.map((elem) => deserializer(elem));
  }
  if (typeof params === "object") {
    const res = {} as Record<string, any>;
    Object.entries(params).forEach(([key, value]) => {
      const newKey = snakecase(key);
      res[newKey] = deserializer(value);
    });
    return res;
  }

  return params;
};
