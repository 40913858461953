import { RefObject, useCallback, useEffect, useState } from "react";

interface Props {
  ref: RefObject<any>;
  isOpen?: boolean;
}

export const useOutsideClick = ({ ref, isOpen }: Props): boolean => {
  const [clickOutside, setClickOutside] = useState(false);

  const handleClose = useCallback(
    (evt) => {
      if (isOpen && !ref?.current?.contains(evt.target)) {
        setClickOutside(true);
      }
    },
    [ref, isOpen]
  );

  const onUnmount = useCallback(() => {
    document.removeEventListener("mouseup", handleClose);
  }, [handleClose]);

  useEffect(() => {
    document.addEventListener("mouseup", handleClose);
    return onUnmount;
  }, [handleClose, onUnmount]);

  return clickOutside;
};

export default useOutsideClick;
