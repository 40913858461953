import React from "react";

import facebook from "../../assets/facebook.svg";
import instagram from "../../assets/instagram.svg";
import whatsapp from "../../assets/whatsapp.svg";
import wave from "../../assets/waves/top-red.svg";

import styles from "./styles.module.scss";

function Footer() {
  return (
    <footer className={`row top column-sm center-sm ${styles.container}`}>
      <img src={wave} alt="" className={styles.wave} />
      <div className="column m-right-40 m-right-sm-0 center-sm full-width-sm m-bottom-sm-20">
        <span className="white m-bottom-8">® Le Tramp 2000-2022</span>
        <span className="white">Todos los derechos reservados</span>
      </div>
      <div className="column full-width center-sm m-bottom-sm-20">
        <span className="white m-bottom-4">Buenos Aires, Argentina</span>
        <span className="white m-bottom-4">Teléfono +54 11 4523 6083</span>
        <a
          href="https://wa.me/5491154739944"
          className="white m-bottom-4"
          target="_blank"
          rel="noopener noreferrer"
        >
          Celular +54 11 5473 9944
        </a>
        {/* https://wa.me/15551234567 */}
        {/* <span className="white m-bottom-4">Celular +54 11 5473 9944</span> */}
        <span className="white">infoletramp@hotmail.com</span>
      </div>
      <div className="row bottom self-bottom m-auto">
        <a
          href="https://wa.me/5491154739944"
          className={`row center m-right-16 ${styles.socialMedia}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={whatsapp} className="min-icon" alt="whatsapp" />
        </a>
        <a
          href="https://www.facebook.com/letramp.gimnasiaacrobatica"
          target="_blank"
          rel="noopener noreferrer"
          className={`row center m-right-16 ${styles.socialMedia}`}
        >
          <img src={facebook} className="min-icon" alt="facebook" />
        </a>
        <a
          href="https://www.instagram.com/letramp.oficial.arg/"
          target="_blank"
          rel="noopener noreferrer"
          className={`row center ${styles.socialMedia}`}
        >
          <img src={instagram} className="min-icon" alt="instagram" />
        </a>
      </div>
    </footer>
  );
}

export default Footer;
