import { create } from "apisauce";

import { serializer } from "../services/serializers";

const baseURL = "";

if (baseURL === "http://bootstrap.com") {
  console.warn("API baseURL has not been properly initialized"); // eslint-disable-line no-console
}

const STATUS_CODES = {
  unauthorized: 401,
};

const api = create({
  baseURL,
  timeout: 15000,
});

// eslint-disable-next-line no-unused-vars, prettier/prettier, @typescript-eslint/no-unused-vars
export const apiSetup = () => {
  api.addMonitor((response) => {
    if (response.status === STATUS_CODES.unauthorized) {
      /*
       * TODO: These callbacks should only be called if no other callback was asigned for the response.
       * - dispatch(alertActions.error(i18next.t('apiErrors:expired')));
       */
    }
  });

  api.addMonitor((response) => {
    if (response.problem === "NETWORK_ERROR") {
      // TODO: These callbacks should only be called if no other callback was asigned for the response.
    }
  });

  api.addResponseTransform((response) => {
    if (response.ok) {
      response.data = serializer(response.data);
    }
  });
};

export default api;
