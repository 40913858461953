import React from "react";
import ReactDOM from "react-dom";

import App from "./app";
import { apiSetup } from "./config/api";
import "./scss/application.scss";
import { unregister } from "./serviceWorker";
import reportWebVitals from "./reportWebVitals";

const render = () => {
  ReactDOM.render(<App />, document.getElementById("root"));
};

// Render once
render();
apiSetup();
unregister();
reportWebVitals();

// Webpack Hot Module Replacement API
// if (module.hot) {
//   module.hot.accept('./app', () => {
//     render();
//   });
// }
