import React, { useState, useCallback, useEffect, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import cn from "classnames";

import Menu from "./components/Menu";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import TopBar from "./components/TopBar";

import { ROUTES } from "./constants";
import styles from "./styles.module.scss";

function App() {
  const [menuOpened, setMenuOpened] = useState(false);
  const [scrollPositionY, setScrollPositionY] = useState(0);

  useEffect(() => {
    if (!menuOpened && scrollPositionY > 0) {
      window.scrollTo({ top: scrollPositionY });
    }
  }, [menuOpened, scrollPositionY]);

  const handleOpenMenu = useCallback(() => {
    setScrollPositionY(window.scrollY);
    document.documentElement.style.setProperty(
      "--scroll-position-y",
      `-${window.scrollY}px`
    );
    setMenuOpened(true);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setMenuOpened(false);
  }, []);

  return (
    <Router>
      <Suspense fallback={<div>Loading</div>}>
        <Menu show={menuOpened} onClose={handleCloseMenu} />
        <div className={cn({ [styles.block]: menuOpened })}>
          <Navbar />
          <TopBar openMenu={handleOpenMenu} />
          <Switch>
            {ROUTES.map(({ id, path, exact, component }) => (
              <Route key={id} path={path} exact={exact} component={component} />
            ))}
          </Switch>
          <Footer />
        </div>
      </Suspense>
    </Router>
  );
}

export default App;
