import React, { ReactNode, useCallback } from "react";
import { useHistory } from "react-router-dom";

interface Props {
  children: ReactNode;
  className?: string;
  delay?: number;
  to: string;
  callback?: () => void;
}

function CustomLink({ children, className, delay = 300, to, callback }: Props) {
  const { push } = useHistory();

  const handleClick = useCallback(() => {
    document.getElementById("root")?.classList.remove("fade-in");
    document.getElementById("root")?.classList.add("fade-out");
    setTimeout(() => {
      push(to);
      callback?.();
      document.getElementById("root")?.classList.add("fade-in");
      document.getElementById("root")?.classList.remove("fade-out");
      window.scrollTo(0, 0);
    }, delay);
  }, [push, to, delay, callback]);

  return (
    <button type="button" onClick={handleClick} className={className}>
      {children}
    </button>
  );
}

export default CustomLink;
