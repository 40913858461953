import React, { useCallback, useEffect, useState } from "react";
import cn from "classnames";

import CustomLink from "../Link";

import logo from "../../assets/logos/logo.png";
import logoWhite from "../../assets/logos/logo-white.png";
import wave from "../../assets/waves/bottom-white.svg";

import { OPTIONS } from "./constants";
import styles from "./styles.module.scss";

function Navbar() {
  const [whiteBackground, setWhiteBackground] = useState(
    document.documentElement.scrollTop > 100
  );

  const handleScroll = useCallback(() => {
    setWhiteBackground(document.documentElement.scrollTop > 100);
  }, []);

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => document.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  return (
    <header
      id="navbar"
      className={cn("row bottom hide-sm", styles.container, {
        [styles.white]: whiteBackground
      })}
    >
      <CustomLink to="/" className="row start m-right-auto">
        <img
          src={whiteBackground ? logo : logoWhite}
          alt="letramp"
          className={styles.logo}
        />
      </CustomLink>
      {OPTIONS.map(({ label, route }) => (
        <CustomLink
          key={label}
          to={route}
          className="m-left-40 nav white uppercase m-bottom-12"
        >
          {label}
        </CustomLink>
      ))}
      <img src={wave} alt="" className={styles.wave} />
    </header>
  );
}

export default Navbar;
